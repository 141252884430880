.table-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  table {
    border-collapse: collapse;
    width: 50%;
  }
  
  thead {
    background-color: #ddd;
  }
  
  th, td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  tr:hover {
    background-color: #f5f5f5;
  }
  
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  