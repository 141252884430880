
  @import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Montserrat:wght@100;300;500&family=Poppins&display=swap');
  .fira-sans{
    font-family: 'Fira Sans', sans-serif;

  }


  @import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Montserrat:wght@100;300;500&family=Poppins&display=swap');
.roboto{
  font-family: 'Montserrat', sans-serif;

}
  

  @import url('https://fonts.googleapis.com/css2?family=Fira+Sans&family=Manrope&family=Montserrat:wght@100;300;500&family=Poppins&display=swap');
.manrope{
  font-family: 'Manrope', sans-serif;
}
 
:root {
  --font-stack: "Barlow", sans-serif;
  --font-manrope: "Manrope", sans-serif;
  --primary: #b3005e !important;
  --primary-hover: #ca046c !important;
  --white: #ffffff;
  --content-heading-color: #060047;
  --grey-bg: #f4f4f4;
  --search-text-color: #1a1a1a;
  --table-th-color: #94918a;
  --table-border-color: #dedede;
  --table-td-color: #1a1a1a;
  --green-status: #32d517;
  --red-status: #de3730;
  --black: #000000;
}
.addScreenModalTextContent {
  display: flex;
  justify-content: center;
  align-items: center;
}
html {
  height: 100%;
}
.addscreenHeaderSection {
  border: none !important;
}
body {
  background: url(../img/body-bg.png) no-repeat center;
  background-size: cover;
}
.login-form-bx.auth-page {
  background-color: transparent;
}
.login-form-bx.auth-page .box-skew .authincation-content:after {
  display: none;
}
.login-form-bx.auth-page .box-skew1:after {
  display: none;
}
.login-form-bx.auth-page .box-skew1 {
  background-color: transparent;
}
.login-form-bx.auth-page .login-logo .logo-icon {
  width: auto;
  height: 48px;
}
.login-form-bx.auth-page h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  margin-bottom: 26px;
  font-family: var(--font-stack);
}
.login-form-bx.auth-page .inner-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  font-family: var(--font-stack);
}
.login-form-bx.auth-page .box-skew .authincation-content {
  background-color: transparent;
}
.login-form-bx.auth-page .inner-content {
  max-width: 450px;
  padding-left: 0;
  padding-right: 30px;
  width: 100%;
}
.login-form-bx.auth-page .tab-content h3 {
  font-style: normal;
  font-weight: 800;
  font-size: 45px;
  line-height: 55px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: var(--white);
  font-family: var(--font-stack);
  margin-bottom: 14px;
}
.login-form-bx.auth-page .nav {
  justify-content: flex-end;
}
.login-form-bx.auth-page .welcome-content-paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.01em;
  color: var(--white);
  opacity: 0.7;
  font-family: var(--font-manrope);
  margin-bottom: 52px;
}
.login-form-bx.auth-page .nav-pills .nav-link {
  border-radius: 0.375rem;
  padding: 0.75rem 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--white);
  line-height: 29px;
  font-family: var(--font-stack);
  margin-right: 24px;
}
.login-form-bx.auth-page .nav-pills .nav-item:last-child .nav-link {
  margin-right: 0;
}
.login-form-bx.auth-page .nav-pills.light .nav-link.active,
.login-form-bx.auth-page .nav-pills.light .show > .nav-link {
  background: transparent;
  color: var(--white);
  box-shadow: none;
  position: relative;
}
.login-form-bx.auth-page .nav-pills.light .nav-link.active:after {
  content: "";
  width: 46px;
  height: 3px;
  background: #9fafff;
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 20px;
}
.login-form-bx.auth-page .auth-tab-content {
  margin-top: 50px;
}
.login-form-bx.auth-page .form-control {
  background-color: transparent;
  color: var(--white);
  height: 49px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 14px 20px;
}
.form-group {
  position: relative;
}
.btn.btn-primary.btn-pink {
  background: var(--primary);
  box-shadow: 0px 12px 21px 4px rgba(109, 1, 58, 0.19);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  border: none;
  font-style: normal;
  font-weight: 800;
  font-size: 19px;
  line-height: 30px;
  letter-spacing: 0.01em;
  color: var(--white);
  font-family: var(--font-manrope);
}
.btn.btn-primary.btn-pink:hover,
.btn.btn-primary.btn-pink:focus,
.btn.btn-primary.btn-pink:active {
  background: var(--primary-hover);
}
.btn-primary.btn-pink:not(:disabled):not(.disabled):active {
  background: var(--primary-hover);
}
.revover-password {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: var(--white);
  font-family: var(--font-stack);
  margin-bottom: 40px;
}
.revover-password:hover {
  color: var(--primary-hover);
}
.add-new-account {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: var(--white);
  margin-top: 20px;
  font-family: var(--font-manrope);
  font-family: var(--font-stack);
}
.signup-link {
  color: var(--white);
  font-weight: 600;
}
.signup-link:hover {
  color: var(--primary-hover);
}
.eye-off {
  position: absolute;
  right: 10px;
  top: 7px;
  cursor: pointer;
}

.login-form-bx.auth-page .form-group.password-textfield .form-control {
  padding-right: 50px;
}
.login-form-bx.auth-page .box-skew .authincation-content {
  box-shadow: none;
  position: relative;
  z-index: 1;
  right: 0;
  top: 0;
  transform: none;
  padding: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 0;
}
.login-center-img {
  background: url(../img/login-img.png) no-repeat center;
  max-width: 600px;
  width: 100%;
  height: 558px;
  background-size: contain;
  position: absolute;
  z-index: -1;
  top: 56%;
  left: 18px;
  transform: translate(43%, -50%);
  right: 0;
}
.login-leftpanel {
  z-index: 2;
}
.btn.btn-outline-primary.btn-google-signin {
  border: 1px solid rgba(255, 255, 255, 0.26);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 20px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  letter-spacing: 0.01em;
  color: var(--white);
  font-family: var(--font-manrope);
  margin-top: 50px;
  min-height: 53px;
}
.btn.btn-outline-primary.btn-google-signin:hover,
.btn.btn-outline-primary.btn-google-signin:active,
.btn.btn-outline-primary.btn-google-signin:focus {
  background: var(--primary-hover);
}
.btn.btn-outline-primary.btn-google-signin img {
  width: 24px;
  height: 24px;
}
.btn.btn-outline-primary.btn-google-signin:not(:disabled):not(
    .disabled
  ):active {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}

/* Login Page End here */

/* Screen Page start here */

[data-sidebar-style="overlay"] .deznav.sidebar-nav-common {
  left: 0;
}
[data-header-position="fixed"] .header.header-custom {
  display: none;
}
.nav-control.nav-hamburger-menu {
  display: none;
}
.nav-header.navbar-header-custom {
  background-color: transparent;
  height: auto;
  /* top: 68px; */
  top: 40px;
}
.nav-header.navbar-header-custom .sidebar-logo {
  width: 169px;
  height: 64px;
}
.nav-header.navbar-header-custom .brand-logo {
  padding-left: 34px;
  padding-right: 25px;
}
.nav-header.navbar-header-custom {
  width: 15.313rem;
}
.sidebar-nav-common.deznav {
  background-color: transparent;
  padding-top: 108px;
  width: 15.313rem;
}
[data-sidebar-style="overlay"] .content-body.content-body-custom {
  margin: 34px;
  margin-left: 245px;
  padding: 0;
  height: calc(100% - 68px);
}
[data-header-position="fixed"] .content-body.content-body-custom {
  padding-top: 0;
}
.content-body.content-body-custom {
  margin: 34px;
  margin-left: 245px;
  padding: 0;
  height: calc(100% - 68px);
}
.content-body.content-body-custom .container-fluid {
  min-height:600px !important;
  background: var(--white);
  box-shadow: 0px 2px 5px rgba(0, 19, 51, 0.1);
  border-radius: 60px;
  /* min-height: auto !important; */
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.content-body-custom .table-responsive {
  overflow: auto;
  min-height: calc(100% - 100px);
}
.content-body-custom .table-responsive {
  flex: 1;
}
.footer-custom.footer {
  display: none;
}
.sidebar-nav-common.deznav .metismenu > li > a {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
  font-family: var(--font-stack);
  align-items: center;
  display: flex;
}
.deznav.sidebar-nav-common .deznav-scroll {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.sidebar-nav-common.deznav .metismenu li {
  margin-bottom: 5px;
}
.sidebar-nav-common .mm-wrapper {
  display: flex;
  flex: 1;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .sidebar-nav-common.deznav
  .metismenu
  > li.mm-active
  > a {
  background: transparent;
  color: var(--primary);
}
[data-sidebar-style="full"][data-layout="vertical"]
  .sidebar-nav-common.deznav
  .metismenu
  > li
  > a:before {
  display: none;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .sidebar-nav-common.deznav
  .metismenu
  > li
  > a {
  color: var(--white);
}
[data-sidebar-style="full"][data-layout="vertical"]
  .sidebar-nav-common.deznav
  .metismenu
  > li.mm-active
  > a
  i {
  color: var(--white);
}
[data-sidebar-style="full"][data-layout="vertical"]
  .sidebar-nav-common.deznav
  .metismenu
  > li
  > a
  i {
  color: var(--white);
}
[data-sidebar-style="full"][data-layout="vertical"]
  .sidebar-nav-common.deznav
  .metismenu
  > li
  > a {
  padding: 14px 10px 14px 36px;
}
.sidebar-nav-common.deznav .metismenu > li.mm-active > a {
  color: var(--primary-hover);
}
.sidebar-nav-common.deznav .metismenu > li:hover > a,
.sidebar-nav-common.deznav .metismenu > li:focus > a {
  color: var(--primary-hover);
}
.nav-menu-icon {
  width: 24px;
  margin-right: 20px;
}
.nav-menu-icon img {
  width: 24px;
}
.logout-link li a {
  padding: 14px 10px 14px 30px;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--white);
  font-family: var(--font-stack);
  align-items: center;
}
.logout-link li a img {
  width: 24px;
}
#main-wrapper {
  height: 100vh;
}

.custom-content-heading h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  color: var(--content-heading-color);
  font-family: var(--font-stack);
  margin-bottom: 20px;
}
.btn.btn-info.add-screen-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  min-height: 50px;
  border-radius: 12px;
  background: var(--primary);
  border-color: var(--primary);
  font-style: normal;
  font-weight: 700;
  font-size: 15.5px;
  line-height: 22px;
  text-align: center;
  font-family: var(--font-manrope);
}
.btn.btn-info.add-screen-btn:hover,
.btn.btn-info.add-screen-btn:focus,
.btn.btn-info.add-screen-btn:active {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}
.btn.btn-info.add-screen-btn:not(:disabled):not(.disabled):active {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}
.btn.btn-info.add-screen-btn .btn-icon-right {
  border-left: none;
  padding: 1rem 0 1rem 0.6rem;
  margin-left: 0;
  color: var(--white);
}
.btn.btn-info.add-screen-btn .add-new-icon img {
  width: 20px;
  height: 20px;
  margin-left: 0.3rem;
}
.btn.btn-info.add-screen-btn .btn-icon-right .glyph-icon {
  font-size: 12px;
}

.btn.add-media-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  min-height: 50px;
  border-radius: 12px;
  background: var(--primary);
  border-color: var(--primary);
  font-style: normal;
  font-weight: 700;
  font-size: 15.5px;
  line-height: 22px;
  text-align: center;
  font-family: var(--font-manrope);
  color: var(--white);
}
.btn.add-media-btn:hover,
.btn.add-media-btn:focus,
.btn.add-media-btn:active {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
  color: var(--white);
}
.btn.add-media-btn:not(:disabled):not(.disabled):active {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
  color: var(--white);
}
.btn.add-media-btn .btn-icon-right {
  border-left: none;
  padding: 1rem 0 1rem 0.6rem;
  margin-left: 0;
  color: var(--white);
}
.btn.add-media-btn .add-new-icon img {
  width: 20px;
  height: 20px;
  margin-left: 0.3rem;
}
.btn.add-media-btn .btn-icon-right .glyph-icon {
  font-size: 12px;
}
.search-textfield {
  max-width: 450px;
  width: 100%;
}
.search-textfield .form-group {
  width: 100%;
  flex: 1;
}
.search-textfield .form-control {
  background: var(--grey-bg);
  border-radius: 12px;
  border: none;
  height: 58px;
  padding: 18px 47px 18px 18px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: var(--search-text-color);
  font-family: var(--font-manrope);
}
.search-textfield .form-control:hover,
.search-textfield .form-control:focus {
  background: var(--grey-bg);
}
.search-textfield .search-icon {
  width: 22px;
  height: 22px;
  position: absolute;
  right: 18px;
  top: 18px;
  cursor: pointer;
}
.btn.btn-primary.icon-btn {
  background: var(--primary);
  border-color: var(--primary);
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 12px;
}
.btn.btn-primary.icon-btn img {
  width: 24px;
  height: 24px;
}
.btn.btn-primary.icon-btn:hover,
.btn.btn-primary.icon-btn:focus,
.btn.btn-primary.icon-btn:active {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}
.btn.btn-primary.icon-btn:not(:disabled):not(.disabled):active {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}
.table.custom-table {
  white-space: nowrap;
}
.table.custom-table thead th {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.015em;
  font-family: var(--font-manrope);
  color: var(--table-th-color);
  text-transform: capitalize;
  border-color: var(--table-border-color);
}
.table.custom-table .td-content {
  display: flex;
  flex-direction: column;
  color: var(--table-td-color);
}
.table.custom-table td {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
  border: none;
}
.table.custom-table .td-content strong {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  font-family: var(--font-manrope);
}
.table.custom-table .td-content span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  font-family: var(--font-manrope);
  color: #676d6d;
}
.status {
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 16px;
  border-radius: 100%;
}
.status-green {
  background: var(--green-status);
}
.status-red {
  background: var(--red-status);
}
.my-phone-tag {
  border: 0.6px solid #060047;
  border-radius: 37px;
  padding: 6px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  font-family: var(--font-manrope);
  display: inline-block;
  vertical-align: middle;
  width: 70px;
}
 .down-arrow {
  width: 16px;
  height: 16px;
  display: inline-block;
  cursor: pointer;
}
.table.custom-table .table-menu-icon {
  width: 30px;
  height: 30px;
}
.table.custom-table .table-menu-icon .menu-img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.dropdown-toggle-menu .dropdown-toggle::after {
  display: none;
}
.dropdown-toggle-menu .dropdown-menu {
  background: var(--white);
  box-shadow: 0px 4px 34px rgba(33, 38, 61, 0.05);
  border-radius: 8px;
  min-width: 279px;
  padding: 20px;
}
.dropdown-list-item .dropdown-list-icon {
  margin-right: 8px;
  width: 28px;
  height: 28px;
}
.dropdown-list-item .dropdown-list-icon img {
  width: 28px;
  height: 28px;
}
.dropdown-list-item .dropdown-menu-list {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.dropdown-list-item.dropdown-item {
  border-bottom: 1px solid #d8d8d8;
}
.dropdown-toggle-menu .dropdown-menu .dropdown-item {
  padding: 0.5rem 0;
}
.dropdown-toggle-menu .dropdown-item.active,
.dropdown-toggle-menu .dropdown-item:active {
  background-color: transparent;
}
.dropdown-list-item .dropdown-menu-list .menu-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
  margin-bottom: 4px;
}
.dropdown-list-item .dropdown-menu-list .menu-description {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
}
.dropdown-toggle-menu .dropdown-item:hover,
.dropdown-toggle-menu .dropdown-item:focus {
  background-color: #ffffff;
}
.dropdown-toggle-menu .dropdown-item:hover .menu-heading,
.dropdown-toggle-menu .dropdown-item:hover .menu-description {
  color: var(--primary);
}

/* Modal start here */
.custom-modal .modal-content {
  border-radius: 18px;
}
.custom-modal-medium .modal-dialog {
  max-width: 515px;
}
.custom-modal .modal-header {
  padding: 24px;
  align-items: center;
  border-bottom: none;
}
.custom-modal .modal-header .close {
  position: relative;
  padding: 0;
  opacity: 1;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.custom-modal .modal-header .close img {
  width: 24px;
  height: 24px;
}
.custom-modal .modal-header .modal-title {
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 27px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
}
.custom-modal .modal-body {
  padding: 0 24px;
}
.custom-modal .add-screen-paragraph {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
}
/* Modal End here */
.form-field.form-control {
  padding: 18px 20px;
  height: 55px;
  border: 0.8px solid rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
}
.form-field.form-control:focus,
.form-field.form-control:hover {
  border: 0.8px solid rgba(0, 0, 0, 0.35);
  color: var(--table-td-color);
}
.custom-modal .modal-footer {
  border: none;
  padding: 0 24px 24px;
}
.btn.btn-primary.primary-btn {
  background: var(--primary);
  color: var(--white);
  box-shadow: 0px 0px 15px 3.75px rgba(63, 61, 86, 0.05);
  border-radius: 12px;
  padding: 14px 28px;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  font-family: var(--font-manrope);
  border-color: var(--primary);
  height: 56px;
}
.btn.btn-primary.primary-btn:hover,
.btn.btn-primary.primary-btn:focus,
.btn.btn-primary.primary-btn:active {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}
.btn.btn-primary.primary-btn:not(:disabled):not(.disabled):active {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
}
.btn.btn-outline-light.cancel-btn {
  padding: 14px 28px;
  border: 1px solid #060047;
  box-shadow: 0px 0px 15px 3.75px rgba(63, 61, 86, 0.05);
  border-radius: 12px;
  height: 56px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--content-heading-color);
  font-family: var(--font-manrope);
}
.btn.btn-outline-light.cancel-btn:hover,
.btn.btn-outline-light.cancel-btn:active,
.btn.btn-outline-light.cancel-btn:focus {
  border: 1px solid var(--primary-hover);
  color: var(--primary-hover);
  background: transparent;
}
.btn.btn-outline-light.cancel-btn:not(:disabled):not(.disabled):active {
  background: transparent;
  border-color: var(--primary-hover);
}
.tag-name-row {
  margin-bottom: 20px;
}
.tag-name-row .tag-name {
  border-radius: 12px;
  border: 1px solid var(--content-heading-color);
  padding: 10px 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: var(--content-heading-color);
  font-family: var(--font-manrope);
  margin-right: 14px;
}
.tag-name-row .tag-name-content {
  margin-right: 4px;
}
.tag-name-row .tag-name .tag-close-icon {
  width: 16px;
  height: 16px;
}
.clear-filter-link {
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--black);
  font-family: var(--font-manrope);
  margin-right: 12px;
}
.filter-row h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  font-family: var(--font-manrope);
  color: var(--black);
}
.common-checkbox.custom-control {
  padding-left: 26px;
}
.common-checkbox.custom-control .custom-control-input {
  width: 16px;
  height: 16px;
}
.common-checkbox.custom-control input[type="checkbox"]:after {
  width: 16px;
  height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}
.common-checkbox .custom-control-label:before,
.common-checkbox .custom-control-label:after {
  top: 2px;
  width: 16px;
  height: 16px;
  border-color: #e7e7e7;
}
.common-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: var(--primary);
  background-color: var(--primary);
}
.common-checkbox .custom-control-label::before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.4);
  border-width: 1px;
  border-radius: 0.25rem !important;
}
.common-checkbox .custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
}
.badge.badge-common-light {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  font-family: var(--font-manrope);
  color: var(--black);
  padding: 10px 15px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* Screen Page end here */

/* Media Page start here */

.empty-media-img {
  max-width: 350px;
  margin-bottom: 20px;
}
.empty-media-img img {
  max-width: 350px;
}
.empty-media {
  padding: 50px 0 0;
  overflow: auto;
  margin-right: -31px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.empty-media h3 {
  font-style: normal;
  font-weight: 800;
  font-size: 27px;
  line-height: initial;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
  margin-bottom: 10px;
}
.empty-media p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: var(--table-td-color);
  font-family: var(--font-manrope);
  margin-bottom: 0;
}

.name-img {
  width: 40px;
  height: 40px;
}
.name-img img {
  width: 40px;
  height: 40px;
}
.table.custom-table .td-content.name-td-content {
  flex-direction: inherit;
}
.upload-file-container {
  border: 1px dashed #c2c2c2;
  border-radius: 4px;
  padding: 30px 20px;
  margin-bottom: 15px;
}
.upload-file-container .upload-flie-img {
  width: 80px;
  height: 80px;
  margin-bottom: 8px;
}
.upload-file-container .upload-flie-img img {
  width: 80px;
  height: 80px;
}
.upload-file-container h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
}
.upload-file-container input[type="file"] {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  opacity: 0;
}
.font-weight-500 {
  font-weight: 500 !important;
}
/* Media page end here */
.schedule-name-input-feild{
  width: 200px !important;
  min-width: 150px;
}
.month-schedule-list .total-composition {
  font-size: 13px;
  background: #F9F9F9;
  border-radius: 14px;
  padding: 3px 15px;

  transition: 0.3s;
}
.month-schedule-list  .add-btn {
  border-radius: 5px;
  padding: 4px 10px;
  cursor: pointer;
  background-color: rgb(255, 233, 210);
  font-size: 13px;

}
.month-schedule-list > div{
  border-left: 11px solid rgb(224, 137, 46);
 
  border: 1px solid #d3d3d3;

  border-radius: 4px;
  font-weight: 300;
  font-size: 18px;


  cursor: pointer;
}
.month-schedule-list > div:focus{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;

}

.month-schedule-checkbox input[type="checkbox"]:after {

  content: "";
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
top: 0;
  left: 0;
  border: 1.7px solid #E0892E;
  border-radius: 14px;
  background-color: #fff;
}

.month-schedule-checkbox input[type="checkbox"]:checked:after{
  content: "";
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    background-color:  #F7B500;
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 11px;
    border-radius: 14px;
    padding: 2px 0px 0px 0px;
}
.month-schedule-checkbox input{
  margin-right: 10px;

}
.month-schedule-header-checkbox input{
  margin-right: 10px;
}
.month-schedule-header-checkbox input[type="checkbox"]:after {

  content: "";
  display: block;
  position: relative;
  width: 20px;
  height: 20px;
top: -1px;
  left: -3px;
  border: 1.7px solid #E0892E;
  border-radius: 14px;
  background-color: #fff;
}
.month-schedule-event{
  width: 100%;
  position: absolute;
  bottom: -25px;
  color: #000;
}
.month-schedule-header-checkbox input[type="checkbox"]:checked:after{
  content: "";
    display: block;
    width: 20px;
    height: 20px;
    position: relative;
    background-color:  #F7B500;
    color: rgb(255, 255, 255);
    font-weight: 400;
    font-size: 11px;
    border-radius: 14px;
    padding: 2px 0px 0px 0px;
}
.month-schedule-event div{
  border-left: 3px solid rgb(224, 137, 46);
  display: block;
  border-radius: 5px !important;
  background-color: rgb(255, 233, 210);
  padding: 5px;
}
.fc .fc-button-group > .fc-button{
  background: transparent !important;
  color: black !important;
  border-radius: 15px !important;
  margin-right: 1rem !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  padding: 2px 3px;
  line-height: 0.5 !important;
  /* transform: rotate(90deg); */
  border: 1px solid #EAEBED;
}
.event-list{
  float: left;
  width: 40%;
  margin-top: 10px;
  margin-right: 15px;
  height: 600px;
  overflow-y: auto;

}
.event-list h3{
  padding-bottom: 30px;
}
.integration-container{
 
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fill,minmax(22rem,1fr));
}
.app-card{
  border-radius: 10px;
  background: #FAFAFA;
  width: 100%;
height: 196px;

}
.app-card p{
  font-family: var(--font-manrope);
  color: var(--search-text-color);
  font-size: 18px;
  font-weight: 500;
}
.app-card-menu-img{
  width: 25px;
  height: 25px;
}
.app-card-menu{
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.05);
}
.app-card .dropleft .dropdown-toggle::before{
  display: none;
}
.btn.btn-info.push-screen-btn{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  min-height: 50px;
  border-radius: 12px !important;
  background: #fff !important;
  font-style: normal;
  font-weight: 700;
  font-size: 15.5px;
  line-height: 22px;
  text-align: center;
  font-family: var(--font-manrope);
  color: #1a1a1a;
  border: 2px solid #b3005e !important;
}
.btn.btn-info.push-screen-btn:hover{
  background: #b3005e !important;
  color: #fff;
}
.url-app-form{
  border-right: 2px dashed;
}
.url-app-form-icon{
  background: rgba(179, 0, 94, 0.13);
}
.rss-feed-app-form-icon{
  background: rgba(247, 215, 153, 0.13);
}
.scroll-text-app-form-icon{
  background: rgba(179, 0, 94, 0.13);
}
.weather-app-form-icon{
  background: rgba(113, 170, 255, 0.13);
}
.text-app-form-icon{
  background: rgba(113, 170, 255, 0.13);
}
.clock-app-form-icon{
  background: rgba(222, 78, 58, 0.13);
}
.stocks-app-form-icon{
  background: rgba(128, 195, 38, 0.10);
}
.qr-code-app-form-icon{
  background: rgba(221, 91, 169, 0.10);
}
.air-quality-app-form-icon{
  background: rgba(91, 221, 143, 0.10);
}
.youtube-app-form-icon{
  background: rgba(255, 0, 0, 0.10);
}
.Bulletin-board-app-form-icon{
  background: rgba(242, 214, 141, 0.13);
}
.google-slide-icon{
  background: rgba(242, 214, 141, 0.13);
}
.quote-app-form-icon{
  background: rgba(94, 91, 221, 0.10);
}
.model-info-h{
font-weight: 600;
}
.google-slide-form{
  color:#000
}
.google-slide-form li{
 
  list-style: inside;
}
.youtube-info{
  padding: 20px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.50);
}
.youtube-info ul li{
  list-style: inside;
}
.add-content-bulletinboard{
  border-radius: 8px;
  border: 0.8px solid rgba(0, 0, 0, 0.50);
  padding: 15px;
  color: #000000;
}
.app-modal .modal-content{
  border-radius: 18px;
}
.app-option > div{
  padding: 10px;
  border-radius: 10px;
}
.app-modal-heading{
  font-size: 20px !important;
}
.url-app-form label{
  font-size: 16px;
    font-weight: 500;
   
    color: #1A1A1A;
}
.url-app-form-icon h1{
  font-size: 34px;
  font-weight: 600;
  color: #060047;
}
.fc-scrollgrid-section-body:hover{
  background-color: #fff !important;

}
.fc-scrollgrid-section-body tr:hover{
  background-color: #fff !important;

}
.view-schedule-fullcalender  .fc-highlight{
  background: rgba(188, 232, 241, 1) !important;
}
.view-schedule-list{
  font-weight: 500;
}
.view-schedule-time .total-composition {
  background: rgb(255, 233, 210);
}
.media-list-img-zoom{
  position: relative;
  cursor: pointer;
}
.media-list-img-zoom-plus {
  position: absolute !important;

visibility: hidden;

}
.media-list-img-zoom:hover  .media-list-img-zoom-plus{

  font-size: 29px !important;
  font-weight: 600 !important;

  left: 0 !important;
  right: 0 !important;

  width: 15px !important;
  margin: auto !important;

  color: #fff !important;
  top: 13px !important;
  visibility: visible;
}
.add-file-media{
  width: 140px !important;
    font-size: 14px !important;
    padding: 10px !important;
    height: 50px !important;
}
.setting-page-btn{
  font-size: 16px !important;
  height: 44px !important;
  padding-top: 9px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.role-btn-bg{
background-color: #060047 !important;
color: #fff !important;
}
.add-device-profile-img{
  height: 50px;
  width: 50px;
  background-color: #dad0d01a;
}
.add-device-potrait {
  display: flex;
  gap: 2rem;
  color: #000;
  cursor: pointer;
}
.add-device-potrait div{
  border-bottom: 2px solid #fff;
  padding-bottom: 5px;
}
.add-device-potrait .active{
color: #ca046c;
border-bottom: 2px solid #ca046c;
}
.roles-table thead tr, .roles-table tbody tr{
background-color: #fff;
}
.back-button{
  border: none;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 12px;
  padding-right: 12px;
}
.profile .ipOzdx div:first-child{
overflow: unset;
}
.profile-dropdown::after{
  content: " " !important;
}

.profile-dropdown .dropdown-toggle:after{
  content: " " !important;
}
.qr-app-container{

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}


.qr-app-container .qr-box{
  display: flex;
  width: calc(100% - 150px);
    height: calc(100% - 150px);

    padding: 3rem;
    background: #fff;
    box-shadow: 0px 3px 16px #0000006E;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3rem;
    /* width: fit-content;
    height: auto; */
}
.qr-app-container .qr-box-2zone-landscape{
  display: flex;
  width: calc(100% - 150px);
  height: calc(100% - 150px);

  background: #fff;
  box-shadow: 0px 3px 16px #0000006E;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
 
  grid-gap: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}
.qr-app-container .qr-box .qr{
  border: 5px solid #000;
    padding: 10px;
    border-radius: 10px;
}
.qr-app-container .qr-box .text {
color: #000;
}
.qr-app-container .qr-box .text p{
  font-size: 14px;
  }
  .qr-app-container .qr-box .url {
    color: #000;
    font-size: 18px !important;
    text-align: center;
    background-color: #F99156;
    padding: 5px 20px;
    width: fit-content;
    border-radius: 10px;
    }
  .orange, .orangeUrl{
    background-color: #F99156 !important;
  }
  .skyBlue, .skyBlueUrl{
    background-color: #87CEEB !important;
  }
  
  .lightYellow, .lightYellowUrl{
    background-color: #f6f671 !important;
  }
    .clock-app{
      width: 300px;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
      position: relative;
   

    }
    .clock-app .clock{
      height: 100%;
      width: 100%;
      position: absolute;
      margin-top: 0%;
      background-color: #000;
    }
    .weather-app-bg{
      background-color: rgba(0, 0, 0, 0.8);

    }
    .classic-bg{
      background: url(../img/rainy1.svg);
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .border-bg{
      border-radius: 20px;
    }
    .place-date-time{
      padding: 100px 50px 100px 50px;
    }
    .place-date h1{
      font-size: 46px;
      font-weight: 400;
      color: #fff;
    }
    .place-date p{
      font-size: 20px;
    }
    .time p{
      font-size: 20px;
    }
    .weather-app-bg .row h1{
      font-size: 80px;
      font-weight: 400;
      color: #fff;
    }
    .weather-app-bg .row .temperature h2{
      font-size: 40px;
      font-weight: 400;
      color: #fff;
    }
    .other-day-weather .day{
      font-size: 20px;
      margin-bottom: 0;
    }
    .other-day-weather  h2{
      font-size: 30px;
      font-weight: 400;
      margin-bottom: 20px;
      color: #fff;
      margin-bottom: 0;
    }
    .temperature-box {
      padding-left: 50px;
    }
.air-quality{
  height: 250px;
  width: 250px;
  border: 15px solid rgb(212, 202, 47);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.air-quality h1{
  font-size: 60px;
  font-weight: 600;
}
.air-quality .moderate{
  background-color: rgba(212, 202, 47, 0.2);
    color: rgb(212, 202, 47);
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 6px;
}
.other-detail{
  height: 50px;
  width: 50px;
}
.justify-content-evenly{
  flex-grow: 1;
  justify-content: space-evenly;
}
.other-detail p{
  font-size: 12px;
}
.other-detail h4{
  font-size: 24px;
}
.gap-1{
  gap: 1rem;
}
.bg-color-air-app{
  background-color:  rgba(212, 202, 47, 1);
  padding: 20px;
}
.air-quality-app-container{
  border-radius: 10px;
}
.bulletin-action{
  display: flex;
  gap: 10px;
  align-items: center;
}
.bulletin-bg{
  background-color: #F99156;
}
.single-bulletin-app{

  width: 100%;
  height: 100%;
  /* margin: 0 2% 2% 2%; */
  background: #fff;
  color: #000;
  border-radius: 10px;

}
.single-bulletin-text{
  flex-grow: 1;
  text-align: left;
  padding-left: 25px;
  padding-top: 20px;
}
.stock-table td{
color: #fff;
}
.people-space img{
cursor: pointer;
}
.people-space{
  row-gap: 20px;
}
.people-space .title{
  font-size: 18px;
  color: #1A1A1A;
  font-weight: 500;
}
.gap-2{
  gap: 2rem;
}
.template-app-name label{
color: #1A1A1A;
font-weight: 500;
font-size: 16px;

}
.template-app-name input{
  color: #B3005E;
  border-radius: 12px;
border: 2px solid  #B3005E;
font-weight: 500;
}
.template-add-content div{
  border-radius: 20px;
border: 1px solid #000;
padding: 20px 60px;
}
.template-person-image{
  border-radius: 50%;
}
.template-card{
  border-radius: 20px;
  border: 1.5px solid #1A1A1A;
  padding: 20px;
}
.template-card h3{ 
  color: #1A1A1A;
    font-size: 22px;
}
.template-card p{ 
  color: #1A1A1A;
    font-size: 14px;
    font-weight: 300;
}
.add-template div{
  background-color: #F4F4F4;
  padding: 0px 8px 3px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.add-template .add-btn-template{
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 0px !important;
}
.bulletin-table tr td{
  border: 0;
}
.bulletin-table tr .bulletin-action{

  height: 80px;
}

.news-app-bg{

  background-color: white;
  color:black !important;


}
.news-app-opacity{
  background-color: #000;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}



.news-app-bg-img::after{
  content: "";
  background: rgba(17, 19, 17, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.news-app-bg-img-bottom::after{
  content: "";
  background: rgba(17, 19, 17, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 100%);
}
.blur-bg-news-app{
 
  width: 90%;
  height: 90%;
  padding: 20px;

  overflow: hidden;
  backdrop-filter: blur(14px) saturate(180%);
  -webkit-backdrop-filter: blur(14px) saturate(180%);
  background-color: rgba(76, 76, 76, .42);
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, .125);
  text-align: center;
  z-index: 9;
  color: #fff;
  position: relative;
}
.protrait-rss{
  display: flex;
  justify-content: center;
}
.protrait-rss div{
  min-width: 350px;
}
.checkbox-success .custom-control-input:checked ~ .custom-control-label::before{
  background-color: #b3005e !important;
    border-color: #b3005e !important;
}
.custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
  border: 1.7px solid #b3005e !important;
}

input[type="radio"]:before {
  content: "";
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  bottom: 3px;
  right: 2px;
  border: 1.7px solid #b3005e;
  border-radius: 100px;
  background-color: #fff;}
  .temp4-bg{
    background: url(../img/temp4.svg);
    background-position: center;
    background-size: cover;
  }
  .temp2-bg{
    background: url(../img/tem2.svg);
    background-position: center;
    background-size: cover;
  }
  .temp3-bg{
    background: url(../img/temp3.svg);
    background-position: center;
    background-size: cover;
  }
  .temp5-bg{
    background: url(../img/temp5.svg);
    background-position: center;
    background-size: cover;
  }
  .temp6-bg{
    background: url(../img/temp6.svg);
    background-position: center;
    background-size: cover;
  }
  .fade-in-text {
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  .people-setting{
    width: 33px;
    height: 33px;

    padding: 8px;
    box-shadow: 1px 1px 9px rgba(0,0,0,.34901960784313724);
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .MuiDataGrid-root ,.MuiDataGrid-root--densityStandard{
    border: 0 !important;
  }
  .zone-not-supported-people-space{
    background: linear-gradient(113deg, #f5beb7 10%, #ef86c9 24.5%, #b861d9 80%, #743aec 90%);
  }
  .app-icon{
    height: 58px;
    width: 58px;
  }
  .my-subcription-card{
    border-radius: 11.325px;
    border: 1px solid  #060047;
    box-shadow: 0px 3.77496px 32.0872px 0px rgba(29, 29, 29, 0.06);
    padding: 30px;
    width: fit-content;

  }
  .before-select-date-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    padding: 11px 30px;
    margin: auto;
    opacity: 1;
    margin-top: 55px;
    cursor: pointer;
  }
  .before-select-date-card:hover{
    box-shadow: 0 3px 6px #00000029;

  }
  .before-select-date-card p{
    text-align: center;
  }
  .fc-today-button {
    display: none !important;
  }
  .three-zone-content-playing-div{
    height: 86px;
    width: 150px;
    border: 1px solid;
    border-radius: 10px;
  }
  .three-zone-content-playing-div .zone-1{
   width: 50%;
    /* flex-grow: 1; */
    height: 54px;
    border-right: 1px solid;
  }
.content-icon{
height: 20px;
width: 20px;
object-fit: cover;
  }
 
  .three-zone-content-playing-div  .three-zone-content-img{
    height: 30px;
    object-fit: cover;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .first-zone-content-div .content-icon{
    width: 40px;
  }
  .second-zone-playing-div .content-icon{
    width: 40px;
  }


  .qr-app-container .qr-box-preview{
    display: flex;
    width: calc(100% - 100px);
      height: calc(100% - 100px);
  
      padding: 3rem;
      background: #fff;
      box-shadow: 0px 3px 16px #0000006E;
      border-radius: 20px;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;
      /* width: fit-content;
      height: auto; */
  }
 


  .qr-app-container .qr-box-first-zone-preview{
    display: flex;
    width: calc(100% - 150px);
      height: calc(100% - 150px);
  
      padding: 3rem;
      background: #fff;
      box-shadow: 0px 3px 16px #0000006E;
      border-radius: 20px;
   
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;
    
  }

  .qr-app-container .qr-box-third-zone-preview{
    display: flex;
    width: calc(100% - 50px);
      height: calc(100% - 50px);
  
      padding: 3rem;
      background: #fff;
      box-shadow: 0px 3px 16px #0000006E;
      border-radius: 20px;
   
      justify-content: center;
      flex-wrap: wrap;
      gap: 1rem;
     
  }
  
@media (min-width: 1500px) {
  .login-form-bx.auth-page .container {
    max-width: 1300px;
  }
  .login-center-img {
    transform: translate(49%, -50%);
  }
}
@media (max-width: 1440px) {
  .login-form-bx.auth-page .container {
    max-width: 1200px;
  }
  .btn.btn-outline-primary.btn-google-signin {
    margin-top: 25px;
  }
  .login-form-bx.auth-page .auth-tab-content {
    margin-top: 20px;
  }
  .login-form-bx.auth-page .welcome-content-paragraph {
    margin-bottom: 25px;
  }
  .revover-password {
    margin-bottom: 25px;
  }
  .empty-media-img {
    max-width: 200px;
    margin-bottom: 20px;
  }
  .empty-media-img img {
    max-width: 200px;
  }
}
@media (max-width: 1200px) {
  .login-form-bx .box-skew.login-rightpanel .authincation-content {
    padding: 0 15px;
  }
  .login-center-img {
    max-width: 510px;
    height: 468px;
  }
  .content-body-custom .table-responsive {
    overflow: auto;
  }
  .nav-header.navbar-header-custom {
    z-index: 1 !important;
  }
}
@media (max-width: 991px) {
  .login-center-img {
    display: none;
  }

  .login-form-bx.auth-page .box-skew .authincation-content {
    justify-content: center;
  }
  [data-sidebar-position="fixed"][data-layout="vertical"]
    .deznav.sidebar-nav-common {
    position: fixed;
    left: -100%;
  }
  [data-sidebar-style="overlay"] .content-body.content-body-custom {
    margin: 65px;
    margin-left: 0;
    padding: 20px;
    height: auto;
    width: 100%;
    margin-bottom: 20px !important;
  }
  .form-head.d-flex.align-items-start {
    display: flex !important;
    align-items: center !important;
  }
  .form-head .btn.btn-info.add-screen-btn {
    white-space: nowrap;
    margin-right: 10px !important;
  }
  #main-wrapper {
    height: auto;
  }
  .nav-header.navbar-header-custom .brand-logo {
    padding-left: 20px;
  }
  [data-sidebar-position="fixed"][data-layout="vertical"]
    .nav-header.navbar-header-custom {
    position: absolute;
  }
  .nav-header.navbar-header-custom {
    width: auto;
  }
  .nav-header.navbar-header-custom {
    top: 20px;
  }
  .nav-control.nav-hamburger-menu {
    display: block;
    right: 20px;
    position: fixed;
    top: 41px;
  }
  [data-headerbg="color_1"]
    .navbar-header-custom.nav-header
    .hamburger.is-active
    .line,
  [data-headerbg="color_1"] .navbar-header-custom.nav-header .hamburger .line {
    background: var(--white) !important;
  }
  #main-wrapper.show.menu-toggle .deznav.sidebar-nav-common {
    left: 0;
    background: var(--primary);
    padding: 10px 0;
  }
  .logout-link li a {
    padding: 14px 10px 14px 20px;
  }
  .sidebar-nav-common.deznav .metismenu > li.mm-active > a {
    color: #fff;
  }
  .navbar-header-custom.nav-header .brand-logo {
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  body {
    background-size: auto;
  }
  .login-form-bx.auth-page .inner-content {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
  .login-form-bx .box-skew,
  .login-form-bx .box-skew1 {
    padding: 30px 15px;
  }

  .box-skew.d-flex.pl-0.pr-0.login-rightpanel {
    padding-top: 0;
  }
  .login-form-bx.auth-page .nav {
    justify-content: flex-start;
  }
  body .vh-100 {
    height: auto !important;
  }
  .login-form-bx.auth-page h2 {
    font-size: 30px;
    margin-bottom: 18px;
  }
  .login-form-bx.auth-page .inner-content p {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 0;
  }
  .login-form-bx.auth-page .box-skew1 {
    padding: 15px;
  }
  .login-form-bx.auth-page .tab-content h3 {
    font-size: 34px;
    line-height: normal;
    margin-bottom: 14px;
  }
  .login-form-bx.auth-page .nav-pills .nav-link {
    font-size: 21px;
  }
  .login-form-bx.auth-page .form-control {
    height: 48px;
  }
  .revover-password {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .btn.btn-primary.btn-pink {
    padding: 11px 20px;
    font-size: 18px;
  }
  .btn.btn-outline-primary.btn-google-signin {
    padding: 12px 20px;
    line-height: 25px;
    min-height: 52px;
  }
  .login-form-bx.auth-page .welcome-content-paragraph {
    font-size: 16px;
    line-height: 24px;
  }
  .login-form-bx.auth-page .box-skew .authincation-content {
    width: 100%;
  }
  .custom-content-heading h1 {
    font-size: 28px;
    line-height: normal;
    margin-bottom: 13px;
  }
  .search-textfield .form-control {
    height: 47px;
    padding: 14px 47px 14px 14px;
  }
  .search-textfield .search-icon {
    top: 12px;
  }
  .form-head .btn.btn-primary.icon-btn {
    width: 44px;
    height: 44px;
  }
  .form-head .btn.btn-primary.icon-btn img {
    width: 20px;
    height: 20px;
  }
  .form-head .btn.btn-info.add-screen-btn {
    font-size: 0;
    margin-right: 10px !important;
    padding: 0;
    min-width: 44px;
    min-height: 44px;
  }
  .form-head .btn.btn-info.add-screen-btn .btn-icon-right {
    border-left: none;
    padding: 0;
    margin-left: -4px;
  }
}

@media (max-width: 480px) {
}

.settings-default {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding: 7rem 2rem 0;
  width: 100%;
  align-items: center;
  padding-bottom: 2rem;
}

.thumbnail {
  width: 426px;
  height: 240px;
  float: right;
  margin-right: 20px;
  border-radius: 18px;
  position: relative;
  overflow: hidden;
  position: relative;
  overflow: hidden;
}
.imgContent {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: contain;
  vertical-align: middle;
  border-style: none;
}
.commonBtn {
  color: var(--content-heading-color) !important;
  font-family: var(--font-stack);
}
.image-container {
  background: #f4f4f4;
  box-shadow: 0px 2px 4px rgba(0, 21, 64, 0.05),
    0px 0px 2px rgba(0, 21, 64, 0.14);
  border: 1px solid #3a4245;
  width: 233px;
  height: 152px;
  border-radius: 20px;
}

.image-text {
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--table-td-color);
  margin-bottom: 16px;
  font-family: var(--font-manrope);
}
.image-text2 {
  /* position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%); */
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: var(--table-td-color);
  font-family: var(--font-manrope);
}

.devicebg {
  height: 200px;
  border: 1px solid;
  border-radius: 20px;
}

.screenText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
  margin-top: 20px;
  margin-bottom: 20px;
}

.alternetTextSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alternetTextSection span {
  font-family: var(--font-manrope);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--table-td-color);
  margin-top: 10px !important;
}

.alternetTextSection p {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  font-family: var(--font-manrope);
  margin-bottom: 0;
  color: var(--primary);
  cursor: pointer;
}
.font22 {
  font-size: 22px !important;
}

.webBrowserOptionSection {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  cursor: pointer;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.16), 0 0px 0px rgba(0, 0, 0, 0.23);
  transition: box-shadow 129ms;
  width: 80%;
  background-color: white;
  padding: 8px;
  border-radius: 7px;
}
.try-card-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.try-card-img img {
  max-width: 186px;
  width: 100%;
  margin: auto;
}
.tryBrowser {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: var(--table-td-color);
  font-family: var(--font-manrope);
  margin-bottom: 6px;
}
.tryBrowserText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--table-td-color);
  font-family: var(--font-manrope);
}

.browserImg {
  max-height: 200px;
  margin-right: 14px;
}
.web-player-body {
  margin-right: 245px !important;
}
button#Fullscreen {
  border: none;
  position: fixed;
  top: 20px;
  right: 20px;
  background: 0 0;
}
.full-text {
  display: flex;
  background: #333;
  margin: 0 -7px;
  padding: 0 15px;
  border-radius: 7px;
}
.sec-block {
  display: flex;
  align-items: center;
  color: #fff;
  border: 1px solid #fff;
  margin: 6px 0;
  padding: 2px 4px;
  border-radius: 33px;
  cursor: pointer;
}
.sec-block p {
  margin: 0 !important;
  padding: 0 !important;
  font-family: Rubik, sans-serif;
  font-weight: 300;
  font-size: 13px;
}
.sec-block i {
  width: 25px;
  margin-right: 10px;
}

.main-block {
  text-align: center;
  margin-top: 10vh;
  width: 40%;
  margin-left: 30%;
}

.registration-block {
  margin-top: 2vh;
  background-color: black;
  width: 100%;
  padding: 13px 0px;
  border-radius: 10px;
  border: 1px solid white;
}
.registration-title {
  font-size: 16px;
  color: #d6d6d6 !important;
}
.code {
  font-size: 52px;
  color: #d6d6d6 !important;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.webrowerTextSection {
  margin: 50px;
}
.errorSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.errorSection .alert {
  background: #fff !important;
  color: #f72b50 !important;
  border-radius: 0.375rem;
  padding: 10px;
}
.invalid {
  border: 2px solid red !important;
}
.tag-container {
  display: inline-flex;
  max-width: 160px;
  flex-wrap: wrap;
}
.form-group.enter-code-textfield label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  font-family: var(--font-manrope);
  color: #606060;
}
.form-group.enter-code-textfield .form-field.form-control {
  padding: 7px 0;
  height: 42px;
  border: none;
  border-radius: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 20px;
}
.form-group.enter-code-textfield .form-field.form-control.invalid {
  border: none !important;
  border-bottom: 1px solid red !important;
}
/* .btn.btn-primary.primary-btn.register-screen-modal {
  margin-bottom: 20px;
} */
.using-webplayer-paragraph p {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--table-td-color);
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: var(--font-manrope);
}
.webplayer-list {
  margin-bottom: 20px;
}
.webplayer-list ul {
  margin: 0;
  padding: 0;
  padding-left: 20px;
}
.webplayer-list ul li {
  font-family: var(--font-manrope);
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: var(--table-td-color);
  list-style: disc;
}
.content-body.content-body-custom.web-player-body .container-fluid {
  background: #000000;
  border-radius: 0;
  padding: 5px;
}
.content-body.content-body-custom.web-player-body .col-xl-12 {
  padding: 0;
}
.content-body.content-body-custom.web-player-body {
  max-width: 100%;
  width: 100%;
  height: 100%;
  margin: 0 !important;
}
.web-player-body:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
}
.web-player-body:-webkit-full-screen .webplayer-preview-img, 
.web-player-body:-webkit-full-screen .video-js{
  width: 100%;
  height: 100%;
}
.web-player-body:-webkit-full-screen .webplayer-preview-img, 
.web-player-body:-webkit-full-screen .video-js-rotate{
  width: 100vh;
  height: 100vw;
}

.web-player-body:-webkit-full-screen .webplayer-preview-img, 
.web-player-body:-webkit-full-screen .rotate-class{
  width: 100vw;
  height: 100vw;
}
.rotate-class{
  width: 100vw;
  height: 100vw;
}


.basic-list-group{
  height: 100% ;
  width: 100% ;
}
.how-to-register {
  color: #9f9f9f;
  text-decoration: underline;
}
.guidelines-login {
  color: #9f9f9f;
}
.white-color {
  color: var(--white);
}
.basic-list-group.video-container iframe {
  border: none;
}

.console-reg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: auto;
  bottom: 0;
  left: 0;
  right: 0;
}
.console-reg p {
  background: #fff;
  padding: 8px 18px;
  border-radius: 12px;
  font-family: Rubik, sans-serif;
  color: #1e3131;
  display: flex;
  align-items: center;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1607843137254902);
}
.console-reg em {
  margin-left: 5px;
}
.image-preview-container img {
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.upload-file-in-asset{
  height: 50px;
  width: 50px;
  object-fit: cover;
}

:fullscreen .media-content {
  object-fit: contain;
  /* height: 100vh !important; */
  max-height: 100vh  !important;
}
:fullscreen .rotate-media-content {
  object-fit: contain;
  height: 100vw !important;
  max-height: 100vw  !important;
}
.rotate-main-div{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
:fullscreen .media-content video {
  /* object-fit: contain; */
  height: 100vh;
}
:fullscreen .media-content-rotate video {
  /* object-fit: contain; */
  height: 100vw;
}
:fullscreen .media-content iframe {
  object-fit: contain;
  height: 100vh;
}
:fullscreen .media-content .video-js {
  position: inherit;
}

:fullscreen #consoleReg {
  display: none;
}

.error {
  color: #c02576;
}

.custom-modal-large .modal-dialog {
  max-width: 60%;
}

.video-js {
  width: 100%;
  height: 100%;
}

.loader-button-container {
  position: relative;
  width: 100%;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-button-container button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.details-accordian {
  border: 0.8px solid #1a1a1a;
  border-radius: 16px;
}

/* .details-accordian .accordion__header{
  width: 100%;
  background: none !important;
  border: none;
} */

/* .dropdown-toggle {
  background-color: #b3005e !important;
    border-color: #b3005e !important;
    width: 48px;
    height: 48px;
    padding: 0 !important;
    border-radius: 12px !important;
}
.dropdown-list-img-icon{
  height: auto !important;
  width: auto  !important;
} */

/* Screen Page start here */
.btn.btn-outline-light.edit-screen-btn {
  border: 1px solid #060047;
  border-radius: 12px;
  padding: 14px 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  font-family: var(--font-manrope);
  color: var(--content-heading-color);
}
.btn.btn-outline-light.edit-screen-btn .btn-icon-right {
  font-size: 12px;
  padding: 0;
  margin: 0 0 0 4px;
  border: none;
}
.btn.btn-outline-light.edit-screen-btn .btn-icon-right img {
  width: 20px;
}
.screen-subheading {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: var(--primary);
  font-family: var(--font-manrope);
}
.btn.btn-primary.screen-icon-btn {
  background: var(--primary);
  border-color: var(--primary);
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 4px;
}

.btn.btn-primary.screen-icon-btn:not(:disabled):not(.disabled):active {
  background: var(--primary);
  border-color: var(--primary);
}

.btn.btn-primary.screen-icon-btn img {
  width: 12px;
}
.btn.btn-primary.screen-icon-btn .screenshot-icon {
  width: 15px;
}
.more-icon-dropdown .btn.btn-primary {
  width: 30px;
  height: 30px;
  padding: 0;
  border-radius: 4px;
  background: var(--primary) !important;
  border-color: var(--primary) !important;
}
.more-icon-dropdown .btn.btn-primary:after {
  background: url(../img/dots-icon.png) no-repeat center;
  background-size: 50%;
  font-size: 0;
  width: 9px;
  height: 18px;
}
.location-row .locationt-icon-img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.location-col {
  max-width: 500px;
  width: 100%;
}
.location-col h4 {
  background: var(--grey-bg);
  border-radius: 8px;
  padding: 7px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: var(--table-td-color);
  font-family: var(--font-manrope);
  margin-bottom: 14px;
}
.active-row {
  background: var(--grey-bg);
  border-radius: 8px;
  padding: 7px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: var(--table-td-color);
  font-family: var(--font-manrope);
  margin-bottom: 20px;
  min-height: 38px;
}
.active-row .active-status {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-right: 5px;
  display: inline-block;
  background: var(--green-status);
}
.active-row .deactive-status {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin-right: 5px;
  display: inline-block;
  background: var(--red-status);
}
.custom-accordion .details-accordian {
  border: 0.8px solid #1a1a1a;
  border-radius: 16px;
}

.custom-accordion .details-accordian .accordion__header.collapsed {
  background: transparent;
  border-color: transparent;
  color: #211c37;
  box-shadow: none;
}
.custom-accordion.accordion-primary .accordion__header {
  background: transparent;
  border-color: transparent;
  color: var(--primary);
  box-shadow: none;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  font-family: var(--font-manrope);
}
.custom-accordion.accordion-primary
  .accordion__header.collapsed
  .accordion__header--indicator::before {
  font-size: 15px;
}
.accordion-custom-img img {
 
  max-width: 200px;
  max-height: 86px;
  border-radius: 8px;
  object-fit: cover;
  width: 150px;
}
.custom-accordion .accordion__body--text {
  padding: 0 1.25rem 1.5rem;
}
.accordion-custom-img {
  margin-right: 16px;
}
.accordion-custom-content h6 {
  font-family: var(--font-manrope);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: normal;
  color: #1a1a1a;
}
.accordion-custom-content p {
  font-family: var(--font-manrope);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
  color: #1a1a1a;
  margin-bottom: 0;
}
.edit-compostion {
  margin-left: 2px;
  cursor: pointer;
}
.edit-compostion img {
  width: 16px;
  height: 16px;
}
.accordion-custom-content.flex-1 {
  flex: 1;
}
.schedule-custom-img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}
.schedule-custom-img img {
  width: 40px;
  height: 40px;
}
.active-schedule.accordion-custom-content h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #060047;
}
.active-schedule.accordion-custom-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: #1a1a1a;
  opacity: 0.8;
  margin-bottom: 0;
}
.device-accordion-content {
  padding: 0 11px;
}
.device-accordion-content h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
}
.device-accordion-content p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: rgba(26, 26, 26, 0.7);
  font-family: var(--font-manrope);
}
.badge.badge-common-light.badge-tag {
  font-style: normal;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  font-family: var(--font-manrope);
  color: var(--black);
  padding: 10px 15px;
  border: 1px solid #1a1a1a;
  border-radius: 12px;
}
.tag-added {
  cursor: pointer;
}
.cursorPointer{
  cursor: pointer;
}
.tag-added img {
  width: 25px;
  height: 25px;
}
.accordion-overflow {
  overflow-x: hidden;
  overflow-y: auto;
  flex: 1;
  margin-right: -31px;
}
.accordion-overflow-scroll {
  padding-right: 30px;
}
.quickplay-search.search-textfield {
  max-width: 100%;
  margin-bottom: 20px;
}
.custom-modal-medium.quick-modal .modal-dialog {
  max-width: 728px;
}
.quick-modal-table .table.custom-table .td-content {
  display: flex;
  flex-direction: column;
  color: var(--table-td-color);
}

.quick-modal-table thead {
  background-color: transparent;
}
.windows-modal .chris-imgbox {
  margin-bottom: 24px;
}
.location-field .search-location {
  position: absolute;
  right: 12px;
  top: 45px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.location-field .search-location img {
  width: 24px;
  height: 24px;
}
.location-field .form-field.form-control {
  padding-right: 40px;
}
.update-textfield-label {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
}
.empty-media-img.layout-empty-img img {
  max-width: 300px;
}

/* .event-content {
  display: flex;
  align-items: center;
} */
.fullcalendar-main-container{

display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
height: -webkit-fill-available;
}
.day-schedule-fullcalendar-img {
  
  overflow: hidden;
 
    max-width: 100px;
    min-width: 100px;
    max-height: -webkit-fill-available;
    min-height: 4px;
    border-radius: 10px;
    margin-right: 15px;
    position: relative;
    margin-left: 0.5rem;
    background-color: #dad0d01a;
}

/* .event-details {
  display: flex;
  flex-direction: row;
} */
.small-btn{
  padding: 0 !important;
    font-size: 16px  !important;
    width: 180px  !important;
    height: 45px  !important;
}
.back-button{
  background: #fff;
    border: 1px solid #060047;
    border-radius: 10px;
    padding: 5px 14px;
    font-size: 16px;
}
.small-btn-close{
  width: 100px  !important;
}
.add-tag-btn{
  border: 0;
    padding: 5px 10px;
    background: #b3005e !important;
    color: #fff;
    border-radius: 8px;
    margin-right: 10px;
}

.fullcalendar-time{
  background: white;
  padding: 10px 14px 8px 14px;
  background-color: #F8D56D;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 400;
  
  min-height: 30px;
  color: #1E3131;
  white-space: nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  max-height: 100%;
  overflow: hidden;
  margin-left: 10px;
  margin-bottom: 0px;
  margin-right: 10px;

}
.fullcalendar-title{
  color: #1E3131;
  font-weight: 300;
  font-size: 14px;
  max-height: 100%;
  overflow: hidden;
}
.fc-timeGrid-view {
  background-color: #fff; /* Set the desired background color */
}
.fc-timeGridDay-view table table{
  background-color: #fff; /* Set the desired background color */

}
.fc-event-radio {
  margin-right: 5px;
}

/* .fc-h-event{
  display: table-row !important;
} */
.fullcalendar-delete-btn{
  margin-bottom: 0px;
  margin-right: 6px;
}
@media (max-width: 1024px) {
  html,
  body {
    height: auto;
    --vh: auto !important;
    background-size: cover;
  }
}
@media (max-width: 768px) {
  .empty-media-img.layout-empty-img img {
    max-width: 100%;
  }
  .empty-media {
    margin-right: 0;
  }
}

.add-composition-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 20px;
  min-height: 51px;
  border-radius: 12px;
  background: var(--primary);
  border-color: var(--primary);
  font-style: normal;
  font-weight: 700;
  font-size: 15.5px;
  line-height: 22px;
  text-align: center;
  font-family: var(--font-manrope);
  color: var(--white);
}
.add-composition-btn:hover,
.add-composition-btn:active,
.add-composition-btn:focus {
  color: var(--white);
}
.add-composition-btn .btn-icon-right {
  padding: 0;
  border: 0;
}
.add-composition-btn .btn-icon-right .glyph-icon {
  font-size: 12px;
}
.add-composition-btn .btn-icon-right {
  border-left: none;
  padding: 1rem 0 1rem 0.6rem;
  margin-left: 0;
  color: var(--white);
}
.three-layout-paragrapgh {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  font-family: var(--font-stack);
  color: var(--table-td-color);
}
.layout-selected-column {
  background: var(--white);
  box-shadow: 0px 4px 30px rgba(23, 23, 23, 0.04);
  border-radius: 28.5276px;
  padding: 15px;
  border: 2px solid var(--white);
  /* max-width: 347px; */
  width: 100%;
  margin-bottom: 20px;
}
.layout-row {
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: -32px;
  padding-right: 32px;
}
.layout-selected-column.active {
  border: 2px solid var(--primary);
}
.layout-selected-img {
  margin-bottom: 20px;
}
.layout-selected-img img {
  max-width: 100%;
  /* width: 100%; */
}
img.single-time-zone {
  max-width: 159px;
}
.layout-selected-column h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  font-family: var(--font-stack);
  margin-bottom: 6px;
}
.layout-selected-column p {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #1a1a1a;
  opacity: 0.6;
  margin-bottom: 0;
}
.btn.btn-info.preview-btn {
  padding: 14px 20px;
  border-radius: 12px;
  background: transparent;
  border: 1px solid var(--content-heading-color);
  display: inline-flexbox;
  height: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--content-heading-color);
  font-family: var(--font-manrope);
  min-width: 165px;
}
.btn.btn-info.preview-btn:hover,
.btn.btn-info.preview-btn:active,
.btn.btn-info.preview-btn:focus {
  background: var(--content-heading-color);
  color: var(--white);
}
.btn.btn-info.preview-btn:not(:disabled):not(.disabled):active {
  background: var(--content-heading-color);
  color: var(--white);
}

.btn.btn-info.save-composition-btn {
  padding: 14px 20px;
  border-radius: 12px;
  background: var(--content-heading-color);
  border: 1px solid var(--content-heading-color);
  display: inline-flexbox;
  height: 50px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: var(--white);
  font-family: var(--font-manrope);
  min-width: 165px;
}
.btn.btn-info.save-composition-btn:hover,
.btn.btn-info.save-composition-btn:active,
.btn.btn-info.save-composition-btn:focus {
  background: var(--content-heading-color);
  color: var(--white);
}
.btn.btn-info.save-composition-btn:not(:disabled):not(.disabled):active {
  background: var(--content-heading-color);
  color: var(--white);
}
.yellow-box {
  background: #ffc12b;
  border: 2px solid #1a1a1a;
  border-radius: 3px;
  width: 80px;
  height: 45px;
  display: inline-block;
  margin-right: 10px;
}

.yellow-box-portrait {
  background: #ffc12b;
  border: 2px solid #1a1a1a;
  border-radius: 3px;
  width: 70px;
  height: 100px;
  display: inline-block;
  margin-right: 10px;
}

.zone{
  background: #fff;
  border-radius: 4px;
  width: 60px;
  height: 24px;
  margin-bottom: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  font-family: var(--font-manrope);
  color: var(--black);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 3px #00000073;
  margin-right: 10px;
  border: 0;
}
.zone-active {
  background: #060047 !important;
  color: var(--white) !important;
}
.zone-active:focus {
  background: #060047;
  color: var(--white);
}
.top-div{
 height: 80%;
 
}
.top-div-rotate{
  display: flex;
  justify-content: center;
  align-items: center;
}
.bottom-div{
  height: 20%;
 
}
.third-compoition-container{
  display: flex;
  height: 80%;
}
.third-composition-top-div{
  height: 100%;
  width: 50%;
  display: inline-block;
}

.third-composition-second-div{
  height: 100%;
  width: 50%;
  display: inline-block;
}
.third-composition-third-div{
  height: 20%;
}
.third-composition-second-div .media-content{
  height: 100% !important;
}
.third-composition-top-div .media-content{
  height: 100% !important;
}

.third-composition-third-div{
  height: 20%;
}

.fullscreen-mode  .third-composition-top-div div{ 
  height: 100% !important;
 
}

.fullscreen-mode .third-composition-second-div div{
  height: 100% !important;

}
.fullscreen-mode .third-composition-third-div div{
  height: 100% !important;
}


.fullscreen-mode .top-div div {
  height: 100% !important;
}

.fullscreen-mode .bottom-div div{

  height: 100% !important;

}
.webplayer-composition-full-screen .image-preview-container {
  height: 100% ;
}

.webplayer-composition-full-screen .image-preview-container .webplayer-preview-img{
  height: 100% !important;
}

.duration {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  font-family: var(--font-manrope);
  color: var(--content-heading-color);
}
.layout-table.table.custom-table thead th {
  background: none;
  height: 72px;
  vertical-align: middle;
  border-top: 0.5px solid #dedede;
}
.layout-table.table.custom-table thead {
  background-color: transparent;
}
.flex-1 {
  flex: 1;
}
.layout-table.table.custom-table .tag-container {
  border: 0.6px solid #060047;
  border-radius: 37px;
  padding: 6px 12px;
}
.layout-edit-btn {
  width: 26px;
  height: 26px;
  background: #f4f4f4;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.layout-edit-btn img {
  width: 14px;
}
.custom-comp-table {
  margin-bottom: -35px;
}
.custom-comp-table .border-col {
  border-right: 0.8px solid #dedede;
}
.push-column {
  background: var(--white);
  box-shadow: 0px 3.6409px 21.8454px rgba(0, 0, 0, 0.05);
  border-radius: 10.9227px;
  padding: 18.2045px;
  margin-top: 10px;
  height: 100%;
}
.push-column-icon {
  background: #f4f4f4;
  border-radius: 100%;
  width: 100px;
  height: 100px;
  margin: 0 auto 16px;
  display: block;
}
.push-column-icon img {
  max-width: 38px;
}
.push-column h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 36px;
  color: var(--table-td-color);
  font-family: var(--font-manrope);
}
.push-column p {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  text-align: center;
  color: var(--table-td-color);
  font-family: var(--font-manrope);
  margin-bottom: 0;
}
.layout-row.push-row {
  overflow: visible;
}
.push-column:hover {
  background: var(--primary);
}
.push-column:hover p {
  color: var(--white);
}
.push-column:hover h6 {
  color: var(--white);
}

@media (max-width: 991px) {
  .search-selected.search-textfield {
    margin-left: inherit !important;
    width: 100%;
    max-width: 100%;
}
  .custom-content-heading.selected-heading h1 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .push-column {
    height: auto;
  }
}

:fullscreen .image-preview-container img{
  max-width: 100%;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.videotableName {
    background: black;
    color: white !important;
    justify-content: center;
    align-items: center;
    display: flex;
}
.mediaDUrationTag{
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 60%;
  align-items: center;
  padding: 0px !important;
}

.mediaDUrationTag input{
    width: 34px;
    text-align: center;
    border: none;
    border-radius: 1rem;
    transition: all 0.3s;
    font-weight: 300;
    color: #1e3131;
    font-size: 14px;
    height: 30px;
    float: left;
    padding: 0 3px;
    box-shadow: none !important;
    height: 24.3px !important;
}

.custom-modal-preview  .modal-body{
  padding: 0;
}

.container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.login-form-bx .container {
  display: block;
 
}

.column {
  background-color: #f2f2f2;
  padding: 10px;
}

.mainWrapper{
  position: relative;
  height: 300px;
}

.crop-container{
  height: 300px;
}
.controls{
  position: absolute;
    left: 50%;
   
    transform: translateX(-50%);
    height: 40px;
    display: flex;
    align-items: center;
    width: 75%;
    /* margin: 15px auto; */
}

.controls input{
  -webkit-appearance: none;
    -moz-appearance: none;
    height: 12px;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    background: #b4b7c9;
    width: 100%;
}

.custom-container .form-check {
  position: relative;
  padding-left: 1.25rem;
  display: flex;
  align-items: center;
  color: #1A1A1A;
}

.custom-container .form-check-label {
  margin-top: 3px;
  font-size: 14px;
  font-weight: 500;
}

.custom-modal-preview .modal-footer{
  padding: 2rem 1.875rem;
}
.custom-container {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}

.table-responsive{
  max-height: 300px;
  overflow: auto;
}
.custom-modal-preview {
  overflow: hidden  !important;
}


.basic-list-group.image-preview-container.media-content {

  height: 100%;
  /* border: 3px solid #000000; */
  /* position: relative; */
  top: 0px;
  left: 0px;
  /* max-height: 55vh; */
}

.image-preview-editable img.webplayer-preview-img {
  /* position: absolute; */
  height: 100%;
  width: 100%;
  max-height: 100% !important;
}

input[type="radio"]:before {
  content: "";
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  bottom: 3px;
  right: 2px;
  border: 1.7px solid #b3005e;
  border-radius: 100px;
  background-color: #fff;
}

input[type="radio"]:checked:after {
  content: "";
  /* font-family: FontAwesome; */
  display: block;
  width: 11px;
  height: 12px;
  border-radius: 100px;
  position: relative;
  top: -18px;
  margin-left: 1.75px;
  background-color: #b3005e;
  color: #fff;
  font-weight: 400;
  font-size: 11px;
  padding: 0 0 0 3px;
}

.custom-modal-preview .modal-dialog{
  /* max-width: 54vw !important; */
  margin: 2.5vh auto;
}

.zoneName{
  width: 193px;
  height: 31px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px !important;
  gap: 10px;
  border-radius: 0;
  font-size: 14px;
  margin: 5px 0 20px;
  background: #b3005e;
    color: #fff;
}
.zone-detail-table{
  height: 220px;
  overflow-y: scroll;
}

.new1{
  border-top: 1px dashed #b1b1b1;
}
.item_number {
  width: 40px;
  float: left;
  padding: 0 1%;
  color: #1e3131;
  font-weight: 600;
  font-size: 14px;
  margin-left: 1rem;
}

.img-block {
  overflow: hidden;
  float: left;
  width: 55px;
  height: 40px;
  border-radius: 10px;
  margin-right: 15px;
  position: relative;
  border: 1px solid #eee;
  background-color: #dad0d01a;
}

.file-detail {
  width: 30%;
  padding-left: 3%;
  float: left;
}

.name-content strong{
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  font-family: var(--font-manrope);
  color: var(--table-td-color);
  border: none;
}
.name-content span {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  font-family: var(--font-manrope);
  color: #676d6d;
}
thead {
  background-color: initial !important;
}

.landscape-view .modal-dialog{
  margin: 1rem 8rem !important;
  max-width: 100% !important;
}
/* .potrait-view .modal-dialog{
  max-width: 480px !important;
  margin: 1rem auto !important;
} */
.custom-modal-preview .image-preview-container img {
  max-width: 100%;
  height: 100%;
  width: 100%;

}


.image-preview-editable .react-thumbnail-generator{
  width: 100%;
    height: 100%;
    overflow: hidden;
}


.image-preview-editable .react-thumbnail-generator img{
  max-width: 100%;
  max-height: 100%;
}

.fitImage img{
  object-fit: fill;
}
.containImage img{
  object-fit: contain;
}
.fitImage-rotate video{
  object-fit: contain !important;
}

.fitImage video{
  object-fit: fill;
}
.containImage video{
  object-fit: contain;
}

.fullscreenbtn{
  right: 1rem;
  cursor: pointer;
  font-size: 18px !important;
}

/* .potrait-view .modal-dialog{
  max-width: 480px !important;
  margin: 1rem auto !important;
} */

:fullscreen .modal-header{
  display: none;
}
:fullscreen .modal-footer{
  display: none;
}
:fullscreen .media-content .video-js {
  height: 100%;
  width: 100%;
}
:fullscreen.landscape-view .modal-dialog{
  /* margin: 0px !important; */
  max-width: 100% !important;
}
:fullscreen.custom-modal-preview .modal-content{
  background: radial-gradient(black, transparent);
}
/* .potrait-view .container-fluid{
  max-width: 480px !important;
    margin: 1rem auto !important;
} */

:fullscreen button#Fullscreen{
  display: none;
}

.content-body.content-body-custom.web-player-body .container-fluid {
  background: transparent;
  border-radius: 0;
  padding: 5px;
  box-shadow:none;
}

.activeType{
  color:white !important;
  background: #b3005e !important;
}


  .table-responsive {
    overflow: auto;
    max-height: calc(100vh - 250px);
}
.reactEasyCrop_Container{
  top:19% !important;
  bottom:3% !important;
}
.disabled-checkbox{
  display: none;
}
 .fc-h-event{
  background-color: transparent !important;
}

.fc-v-event{
  background-color: rgb(255, 228, 148) !important;
  border-color: rgb(255, 228, 148) !important;
}

.losers{
  color : #fa1010 !important
}
.gainers{
  color:#87e487 !important
}

.stockTd{
  color:#fff !important;
}


.image-preview-container-rotate{
  height: 100vw !important;
}
/* @media (orientation: portrait) {
  .potrait-view .container-fluid {
    max-width: 100% !important;
    margin: 0 !important;
}
} */
 
@keyframes example {
  0%  {top:0px;}
  100% {top:100px;}
}

.custModal{
  z-index: 99 !important;
}

.MuiDataGrid-root{
  min-height:450px;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}
.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
  overflow: none !important;
  white-space: normal !important;
}
.MuiDataGrid-root MuiDataGrid-root--densityStandard css-iclwpf{
  border:0; 
}
.marginLR{
  margin: 0 10px;
}
.btnnew {
  padding: 5px 10px !important;
}